import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./Proyecto.scss";
import proyectImage from "../../assets/img/proyecto-image.png";
// import promotionalVideo from "../../assets/video/video_promocional.mp4";

const Proyecto = () => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  // const promotionalVideo =
  //   "https://player.vimeo.com/video/765422384?h=35c046b135&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
  const handleClose = () => {
    setShowVideoModal(false);
  };

  return (
    <section id="proyecto" className="container">
      <Modal show={showVideoModal} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <iframe
            width="100%"
            height="650px"
            src="https://www.youtube.com/embed/reNi6YZ3ZI0"
            title="Ríos de Maullín"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe> */}
          {/* <video width="100%" height="auto" controls>
            <source src={"https://youtu.be/reNi6YZ3ZI0"} type="video/mp4" />
            Tu navegador no soporta los vídeos de HTML5
          </video> */}
          {/* <iframe
            src="https://player.vimeo.com/video/765422384?h=35c046b135&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"

            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              width: "100%",
              height: "650px",
            }}
            title="Fundo Armadillo"
          ></iframe> */}
        </Modal.Body>
        <Modal.Footer id="galleryModalFooter">
          <Button variant="dark" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <div id="contentContainer">
        <div id="textContainer">
          <h2 id="title">Sobre el proyecto</h2>
          <h5>
            Fundo Río Sur obtiene su nombre debido a los ríos que acompañan a la
            parcela en su extensión, brindando un terreno rico y con abundante
            vegetación silvestre. La parcelación cuenta con parcelas de 5.000 m2
            todas planas y algunas con conexión directa a los riachuelos del
            sector.
            <br /> <br />
            El Fundo se encuentra bien ubicado al interior de una parcelación
            cercada con portón propio, en el que podrás encontrar dentro de sus
            cercanías, grandes ciudades y todo lo que necesites para una
            desconexión con todo lo que necesitas.
          </h5>
          {/* <Button
            id="videoButton"
            variant="dark"
            size="lg"
            onClick={() => setShowVideoModal(true)}
          >
            Ver video del proyecto
          </Button> */}
        </div>
        <img id="imagenProyecto" src={proyectImage} alt="imagen-proyecto" />
      </div>
    </section>
  );
};

export { Proyecto };
