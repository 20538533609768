import React from "react";
import "./Video.scss";
import promotionalVideo from "../../assets/video/video_promocional.mp4";

const Video = () => {
  return (
    <section id="video" className="container">
      <h2 id="title">Video</h2>
      <video width="100%" height="auto" controls>
        <source src={promotionalVideo} type="video/mp4" />
        Tu navegador no soporta los vídeos MP4
      </video>
    </section>
  );
};

export { Video };
