import React from "react";
import "./Sector.scss";
import img1 from "../../assets/img/imgsSector/parque-nacional.jpg";
import img2 from "../../assets/img/imgsSector/carretera-austral.jpg";
import img3 from "../../assets/img/imgsSector/lagos-pesca.jpg";
import img4 from "../../assets/img/imgsSector/gastronomia.jpg";
import img5 from "../../assets/img/imgsSector/cercania.jpg";

const Sector = () => {
  return (
    <section id="sector" className="container">
      <h3 id="subtitle">Atractivos del sector</h3>
      <h1 id="title">Región de Los Lagos</h1>
      <div className="sectorItem">
        <div className="itemImageContainer">
          <img className="itemImage" src={img1} alt="itemImage1" />
        </div>
        <div className="textContainer">
          <h4>Parques Nacionales</h4>
          <p>
            Los parques nacionales de la zona tienen algunas de las más bellas
            especies de flora y fauna nativa, un lugar para desconectarse del
            mundo y disfrutar de su maravillosa naturaleza. Entre los
            principales parques cercanos destacan:
          </p>
          <ul>
            <li>Parque Nacional Alerce Andino</li>
            <li>Parque Salto Río Blanco</li>
            <li>Parque Nacional Chiloé</li>
          </ul>
        </div>
      </div>
      <div className="sectorItem inverseImageContainer">
        <div className="itemImageContainer">
          <img className="itemImage" src={img2} alt="itemImage2" />
        </div>
        <div className="textContainer">
          <h4>Carretera Austral</h4>
          <p>
            Una carretera única de 1200 kilómetros, que conecta desde Puerto
            Montt a Villa O'Higgins y recibe cientos de turistas año a año de
            todas partes del mundo.
          </p>
        </div>
      </div>
      <div className="sectorItem">
        <div className="itemImageContainer">
          <img className="itemImage" src={img3} alt="itemImage3" />
        </div>
        <div className="textContainer">
          <h4>Ríos de Pesca</h4>
          <p>
            La zona cuenta con muchos ríos para pescar, dentro de los cuales el
            más destacado es el río Maullín conocido como el "Río Salvaje".
          </p>
        </div>
      </div>

      <div className="sectorItem inverseImageContainer">
        <div className="itemImageContainer">
          <img className="itemImage" src={img4} alt="itemImage4" />
        </div>
        <div className="textContainer">
          <h4>Gastronomía Local</h4>
          <p>
            El sur de Chile destaca por la gastronomía local de cada una de sus
            zonas. Puerto Montt, Chiloé y otras localidades te invitan a
            sorprenderte con su gastronomía.
          </p>
        </div>
      </div>

      <div className="sectorItem">
        <div className="itemImageContainer">
          <img className="itemImage" src={img5} alt="itemImage5" />
        </div>
        <div className="textContainer">
          <h4>Conectividad y Cercanía</h4>
          <p>
            Rápido acceso al Aeropuerto El Tepual y a todos los servicios que
            necesitas en el centro de la ciudad de Puerto Montt:
          </p>
          <ul>
            <li>Colegios</li>
            <li>Instituciones públicas</li>
            <li>Clínicas y Bancos</li>
            <li>Supermercados y Servicios</li>
            <li>Comisaría</li>
            <li>Embarcadero</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export { Sector };
