import React from "react";
import { Button, Image } from "react-bootstrap";
import "./Caracteristicas.scss";
import mapImage from "../../assets/img/mapa.png";
// import logo from "../../assets/img/favicon.png";

const Caracteristicas = () => {
  const features = [
    { text: "Portón de acceso al fundo." },
    { text: "Listas para escriturar." },
    {
      text: "Parcelas desde 5000m2.",
    },
    { text: "Simple Acceso." },
    { text: "Terrenos planos." },
    { text: "Factibilidad de Agua y Luz." },
    { text: "A 10 minutos de Los Muermos." },
    {
      text: "A 40 minutos de Puerto Montt. ",
    },
    {
      text: "A 45 minutos de Puerto Varas",
    },
  ];

  return (
    <section id="caracteristicas" className="container">
      <h2 id="title">Características y Ubicación</h2>
      <div id="contentContainer">
        <div id="mapContainer">
          <Image id="mapImage" src={mapImage} alt="mapa" />
          <a
            href="https://maps.app.goo.gl/o6jxeQW5GswuPx7W8"
            rel="noreferrer"
            target="_blank"
          >
            <Button variant="dark" size="lg">
              Abrir en Google Maps
            </Button>
          </a>
        </div>
        <div id="caracteristicasContainer">
          {features.map((f, i) => (
            <div key={i} className="caracteristicaContainer">
              <h4 className="caracteristicaIcon">
                {/* <Image id="listIcon" src={logo} alt="logo" /> */}•
              </h4>
              <h5>{f.text}</h5>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { Caracteristicas };
