import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./Home.scss";
import { Proyecto } from "../Proyecto";
import { Video } from "../Video";
import { Caracteristicas } from "../Caracteristicas";
import { TourVirtual } from "../TourVirtual";
import { Galeria } from "../Galeria";
// import { Contacto } from "../Contacto";
import homeVideo from "../../assets/video/video-header.mp4";
import videoPoster from "../../assets/img/video-poster-image.png";
import { Sector } from "../Sector";

const Home = () => {
  return (
    <React.Fragment>
      <div id="home" className="container">
        <video id="background-video" autoPlay loop muted poster={videoPoster}>
          <source src={homeVideo} />
        </video>
        <h1 id="title">
          <span id="preTitle">Fundo Río Sur</span>
        </h1>
        <h4 id="subtitle">
          Comparte en familia en un lugar privilegiado del sur de chile.
          <br />A minutos de Los Muermos, Puerto Varas, Puerto Montt y el
          aeropuerto de El Tepual.
        </h4>
        <Button
          className="homeButton"
          variant="outline-light"
          size="lg"
          href="#caracteristicas"
        >
          Más información
        </Button>
        {/* <Button
          className="homeButton"
          variant="dark"
          size="lg"
          href="#contacto"
        >
          Contáctanos
        </Button> */}
        <div id="homeCardsContainer" className="container">
          <div className="homeCard">
            <h3>El Proyecto</h3>
            <p>
              45 parcelas completamente planas, rodeadas de riachuelos y
              vegetación que generan una tranquilidad única para quienes las
              visitan.
            </p>
          </div>
          <div className="homeCard">
            <h3>Cerca de Todo</h3>
            <p>
              A menos de 10 minutos tendrás acceso a Los muermos una comuna en
              constante crecimiento y que ofrece la mayoría de los servicios
              necesarios para vivir en paz y tranquilidad en conexión con el sur
              de Chile.
            </p>
          </div>
          <div className="homeCard">
            <h3>Ubicación</h3>
            <p>
              A sólo 40 minutos del aeropuerto y 60 minutos de Puerto Varas
              encontrarás nuestro proyecto Fundo Río Sur, parcelación ubicada en
              la X región a sólo 10 minutos de los Muermos, presentamos un lugar
              perfecto para despejarse y poder disfrutar de la flora y fauna del
              sur de Chile.
            </p>
          </div>
        </div>
        <div id="homeGeneralCard">
          <h3>El Proyecto</h3>
          <p>
            45 parcelas completamente planas, rodeadas de riachuelos y
            vegetación que generan una tranquilidad única para quienes las
            visitan.
          </p>
          <h3 className="generalTitle">Cerca de Todo</h3>
          <p>
            A menos de 10 minutos tendrás acceso a Los muermos una comuna en
            constante crecimiento y que ofrece la mayoría de los servicios
            necesarios para vivir en paz y tranquilidad en conexión con el sur
            de Chile.
          </p>
          <h3 className="generalTitle">Ubicación</h3>
          <p>
            A sólo 40 minutos del aeropuerto y 60 minutos de Puerto Varas
            encontrarás nuestro proyecto Fundo Río Sur, parcelación ubicada en
            la X región a sólo 10 minutos de los Muermos, presentamos un lugar
            perfecto para despejarse y poder disfrutar de la flora y fauna del
            sur de Chile.
          </p>
        </div>
      </div>
      <Proyecto />
      <Video />
      <Caracteristicas />
      <TourVirtual />
      <Galeria />
      <Sector />
      {/* <Contacto /> */}
    </React.Fragment>
  );
};

export { Home };
